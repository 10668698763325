<template>
	<div class="data-list list-maintenance list-tab-show-page">
		<template v-if="loaded">
			<div class="row-space-tbf header-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="column-filter column-name">
						<div class="text">{{ $t('general.name') }}</div>
					</div>
					<div v-if="$resize && ($mq.above(815) && !$mq.between([1025, 1335]))" class="column-filter column-modification-reason">
                        <div class="text">{{ $t('salary.show-contract.modification_reason') }}</div>
                    </div>
					<div v-if="$resize && ($mq.above(600) && !$mq.between([1025, 1250]))" class="column-filter column-document-number">
                        <div class="text">{{ $t('salary.show-contract.document_number') }}</div>
                    </div>
                    <div v-if="$resize && ($mq.above(500) && !$mq.between([1025, 1100]))" class="column-filter column-start-data">
                        <div class="text">{{ $t('salary.show-contract.active_from') }}</div>
                    </div>
                    <div v-if="$resize && $mq.above(500)" class="column-filter column-end-data">
                        <div class="text">{{ $t('salary.show-contract.active_to') }}</div>
                    </div>
				</div>
				<div class="space-right"></div>
			</div>
			<template v-if="entries.length">
				<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
					<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
						<div class="column-name-title column-name">
							<div class="name">{{ entry.contract_modification_name }}</div>
						</div>
						<div v-if="$resize && ($mq.above(815) && !$mq.between([1025, 1335]))" class="column-simple-text column-modification-reason">
                            <div class="text">{{entry.modification_reason_name}}</div>
                        </div>
						<div v-if="$resize && ($mq.above(600) && !$mq.between([1025, 1250]))" class="column-simple-text column-document-number">
                            <div class="text">{{entry.document_number }}</div>
                        </div>
                        <div v-if="$resize && ($mq.above(500) && !$mq.between([1025, 1100]))" class="column-simple-text column-start-data">
                            <div class="text">{{entry.active_from | moment('DD MMM YYYY')}}</div>
                        </div>
                        <div v-if="$resize && $mq.above(500)" class="column-simple-text column-end-data">
                            <div class="text">{{entry.active_to | moment('DD MMM YYYY')}}</div>
                        </div>
						<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
                            <div class="dropdown dropdown-edit">
                                <div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
                                    <icon-edit-mobile />
                                </div>
                                <div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
                                    <div class="dropdown-item" @click="editHistory(entry.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
                                	<div v-if="!entry.initial_contract" class="dropdown-item" @click="showModal('delete', {type: 'history', from: 'index_history', model: entry})"><div class="simple-text">{{$t('general.delete')}}</div></div>
                                </div>
                            </div>
                        </div>
					</div>
					<div class="space-right">
                        <div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
                            <div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
                                <icon-edit />
                            </div>
                            <div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
                                <div class="dropdown-item" @click="editHistory(entry.id)"><div class="simple-text">{{$t('general.edit')}}</div></div>
                                <div v-if="!entry.initial_contract" class="dropdown-item" @click="showModal('delete', {type: 'history', from: 'index_history', model: entry})"><div class="simple-text">{{$t('general.delete')}}</div></div>
                            </div>
                        </div>
                    </div>
				</div>
			</template>
			<template v-else>
				<div class="row-space-tbf row-list-item">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-empty-list">
							<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('congratulations.empty-inline') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
		</template>
		<loader-items-list-2 v-else/>
	</div>
</template>

<script>
	import IconUser from '../../../Icons/User'
	import IconEdit from '../../../Icons/EditDots'
	import IconProcedures from '../../../Icons/Procedures'
	import IconEditMobile from '../../../Icons/Edit'
	import LoaderItemsList2 from '../../../PagesLoaders/ItemsList2'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconProcedures,
			LoaderItemsList2
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		watch:{
			$route (to, from){
				this.getHistory()
			}
		},
		async mounted(){
			await this.getHistory()

			this.$root.$on('refreshHistoryIndex', () => {
				this.getHistory()
			})
		},

		methods: {

			async getHistory(){
				await axios.get(`/${this.$route.params.id}/contract-modifications`)
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
			editHistory(id) {
                this.$router.push({ name: 'contracts-modification-edit', params: {id: id, contract_id: this.$route.params.id} })
            },
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>

<style lang="scss">
    .list-maintenance {
        .row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
        .column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
		}
        .column-modification-reason,
		.column-type,
        .column-document-number{
			flex: 0 0 130px;
		}
        .column-start-data,
        .column-end-data{
			flex: 0 0 90px;
		}
    }
</style>